import React from 'react'
import styled, { css } from 'styled-components'

import { getMonthName } from '../../utils/time'
import isBrowser from '../../utils/isBrowser'
import { colors, device } from '../../styles/config'

const EventInfo = ({ time, name, eventType, className, featured, truncate = false }) => {
  const date = new Date(time)
  const day = date.getDate()
  const month = getMonthName(date.getMonth())
  const eventTime = date.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit'
  })

  const disabled = eventType === 'Privat'

  if (!isBrowser) {
    return null
  }

  return (
    <div className={className}>
      <TimeContainer>
        <FormattedDate>{day}. {month}</FormattedDate>
        {!disabled && <Separator/>}
        {!disabled && <FormattedTime>{eventTime}</FormattedTime>}
      </TimeContainer>
      <Name featured={featured} truncate={truncate}>
        {name}
      </Name>
      <Type>{eventType}</Type>
    </div>
  )
}

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
`

const FormattedDate = styled.div`
  font-size: 0.875rem;
  line-height: 1.625;
  color: ${ colors.highlight };
  letter-spacing: 0.53px;
  font-weight: bold;
`

const Separator = styled.div`
  width: 2.4rem;
  height: 1px;
  background-color: ${ colors.primary };
  margin: 0 0.75rem;
`

const FormattedTime = styled(FormattedDate)`
  font-weight: normal;
  color: ${ colors.primary };
`

const Name = styled.h2`
  margin-bottom: 1.2rem;
  color: ${ props => props.featured && colors.highlight };
  transition: color 0.3s;
  font-size: 2.2rem;

  ${ props => props.truncate && css`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  ` }

  @media ${ device.lg } {
    font-size: 1.7rem;
  }

  @media ${ device.md } {
    font-size: 2.2rem;
  }
`

const Type = styled.div`
  font-size: 0.875rem;
  line-height: 1.625;
  letter-spacing: 0.53px;

  @media ${ device.lg } {
    font-size: 0.8rem;
  }
`

export default EventInfo
