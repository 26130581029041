import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'

import { colors, device } from '../styles/config'

const Container = styled.div`
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  scroll-margin-top: 100px;
  ${ props => props.theme.wrapper }

  h1 {
    margin-top: -1.3%;
  }

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }

    @media ${ device.sm } {
      margin-bottom: 3.125rem;
    }
  }
`

const TextContainer = styled.div`
  ${ props => props.columns && css`
    columns: 2;
    column-gap: 1.875rem;
  ` }

  @media ${ device.sm } {
    columns: initial;
    column-gap: initial
  }

  p {
    margin-bottom: 1rem;
  }
`

const LeftHeader = styled(Col)`
  padding-right: 0;
`

const Header = styled.h1`
  hyphens: auto;
`

const TextSection = ({ name, header, text, columns, className, id }) => (
  <Container className={className} id={id}>
    <Row>
      <LeftHeader xs={12} sm={3}>
        <h4>{name}</h4>
      </LeftHeader>
      <Col xs={12} sm={9}>
        <Header>{header}</Header>
      </Col>
    </Row>
    <Row>
      <Col xs={12} smOffset={3} sm={columns ? 8 : 6}>
        <TextContainer
          columns={columns}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Col>
    </Row>
  </Container>
)

TextSection.propTypes = {
  name: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
}

export default TextSection
