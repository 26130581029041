import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import ReactModal from 'styled-react-modal'

import Event from './Event'
import EventInfo from './EventInfo'
import CloseSvg from '../../images/close.svg'
import { colors, device } from '../../styles/config'

const EventCard = ({
  name,
  time,
  featured,
  eventType: {
    eventType
  },
  description,
  details,
  video,
  image
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const disabled = eventType === 'Privat'

  function openModal () {
    if (disabled) {
      return
    }
    setModalOpen(true)
  }

  function closeModal () {
    setModalOpen(false)
  }

  return (
    <Column xs={12} sm={6} md={4}>
      <Container disabled={disabled} image={image}>
        <InnerContainer disabled={disabled} onClick={openModal}>
          <EventInfo
            time={time}
            name={name}
            eventType={eventType}
            featured={featured}
            truncate
          />
        </InnerContainer>
      </Container>
      <ReactModal
        isOpen={modalOpen}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
        allowScroll={true}
      >
        <CloseButton onClick={closeModal}>
          <CloseSvg/>
        </CloseButton>
        <Event
          time={time}
          name={name}
          eventType={eventType}
          description={description}
          details={details}
          video={video}
          image={image}
        />
      </ReactModal>
    </Column>
  )
}

const Column = styled(Col)`
  margin-bottom: 1.875rem;
`

const Container = styled.div`
  background: white;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  cursor: ${ props => !props.disabled && 'pointer' };
  pointer-events: ${ props => props.disabled && 'none' };
  transition: box-shadow 0.3s;

  @media ${ device.hover } {
    &:hover {
      box-shadow: 20px 2px 44px 0 rgba(208,208,215, 0.61);

      h2 {
        color: ${ colors.secondary };
      }
    }
  }

`

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding:  2.5rem;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${ device.lg } {
    padding: 2.1rem;
    padding-top: 3.2rem;
  }

  @media ${ device.md } {
    padding: 3.25rem;
    padding-top: 5.5rem;
  }

  ${ props => props.disabled && css`
    background: #D0D0D7;
    
    div, h2 {
      color: white;
    }
  ` }
`

const CloseButton = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  right: 2.875rem;
  top: 2.875rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  transform: translate3d(0,0,0);
  z-index: 1;

  > svg {
    width: 30px;
    height: 30px;

    path {
      transition: fill 0.3s;
    }
  }

  @media ${ device.hover } {
    &:hover {
      svg {
        path {
          fill: ${ colors.primary };
        }
      }
    }
  }

  @media ${ device.sm } {
    right: 1rem;
    top: 1rem;
  }
`

export default EventCard
