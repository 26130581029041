import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'

import useCarouselState from '../../hooks/useCarouselState'
import HomepageImageCarousel from './HomepageImageCarousel'
import HomepageTextCarousel from './HomepageTextCarousel'
import { useLuminance } from '../../hooks/luminanceContext'

import OpeningTimes from '../OpeningTimes'

import { colors, device, headerHeight } from '../../styles/config'
import ArrowLeftSvg from '../../images/arrow-left.svg'
import ArrowRightSvg from '../../images/arrow-right.svg'

const Container = styled.div`
  ${ props => props.theme.wrapper };
  padding-right: 0;
  margin-bottom: 13.75rem;

  @media ${ device.md } {
    padding-right: 0;
  }

  @media ${ device.lg } {
    padding-right: 0;
  }

  @media ${ device.sm } {
    padding: 0;
    overflow: hidden;
    margin-bottom: 0;
  }

  ${ Row } {
    align-items: flex-start;
  }
`

const TextContainer = styled(Col)`
  align-self: center;
  padding-top: ${ headerHeight.desktop };
  padding-bottom: ${ headerHeight.desktop };

  @media ${ device.md } {
    padding-top: 0;
    padding-bottom: 0;
    align-self: flex-end;
  }

  @media ${ device.sm } {
    align-self: initial;
    order: 1;
    display: flex;
    flex-direction: column;
    transform: translate3d(0,-30%,0);
    z-index: 1;
    align-items: center;
  }
`

const Controls = styled.div`
  margin-top: 4rem;
  display: flex;

  @media ${ device.md } {
    position: absolute;
    left: 39%;
  }

  @media ${ device.sm } {
    position: initial;
    left: initial;
    width: 100%;
    display: flex;
    margin-top: 0;
    padding-left: 2rem;
    margin-top: -1px;
    background-color: ${ colors.primary };
    padding-bottom: 1rem;
  }
`

const Previous = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;  

  svg{
    transition: transform 0.3s;
    transform: translate3d(0,0,0);

    path {
      fill: ${ colors.primary };

      @media ${ device.sm } {
        fill: white;
      }
    }
  }

  @media ${ device.hover } {
    &:hover {
      svg {
        transform: translate3d(-10px,0,0);
      }
    }
  }

  @media ${ device.sm } {
    margin: 0;
    margin-right: 1rem;
    justify-content: center;
  }
`

const Next = styled(Previous)`
  margin-right: 0;
  justify-content: flex-end;

  @media ${ device.hover } {
    &:hover {
      svg {
        transform: translate3d(10px,0,0);
      }
    }
  }

  @media ${ device.sm } {
    margin: 0;
    justify-content: center;
  }
`

const HomepageHeader = ({ slideshow, onProgrammeClick, winterMode }) => {
  const [, setLuminance] = useLuminance()
  const imageCarouselState = useCarouselState(slideshow.length)
  const textCarouselState = useCarouselState(slideshow.length)

  useEffect(() => {
    if (imageCarouselState.slide === undefined) {
      return
    }

    setLuminance(slideshow[imageCarouselState.slide].luminance)
  }, [imageCarouselState])

  const next = () => {
    imageCarouselState.next()
    setTimeout(textCarouselState.next, 300)
  }

  const previous = () => {
    imageCarouselState.previous()
    setTimeout(textCarouselState.previous, 300)
  }

  return (
    <Container>
      <Row>
        <TextContainer xs={12} sm={4} lg={5}>
          <OpeningTimes/>
          <HomepageTextCarousel
            slideshow={slideshow}
            currentSlide={textCarouselState.slide}
          />
          {slideshow.length > 1 &&
            <Controls>
              <Previous onClick={previous}><ArrowLeftSvg/></Previous>
              <Next onClick={next}><ArrowRightSvg/></Next>
            </Controls>
          }
        </TextContainer>
        <Col xs={12} sm={8} lg={7}>
          <HomepageImageCarousel
            slideshow={slideshow}
            currentSlide={imageCarouselState.slide}
            onProgrammeClick={onProgrammeClick}
          />
        </Col>
      </Row>
    </Container>
  )
}

HomepageHeader.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

export default HomepageHeader
