import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Carousel from '../Carousel'

import { colors, device } from '../../styles/config'
import isBrowser from '../../utils/isBrowser'

const CarouselWithBackground = styled(Carousel)`
  @media ${ device.sm } {
    background-color: ${ colors.primary };
    color: white;
  }
`

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media ${ device.sm } {
    padding: 2rem;
    padding-bottom: 0.5rem;
    height: 100%;
    justify-content: flex-start;
  }
`

const Text = styled.div`
  padding-right: 4rem;

  @media ${ device.lg } {
    padding-right: 0;
  }

  /* @media ${ device.md } {
    display: none;
  } */
`

const Header = styled.h1`
  color: ${ props => props.color };
  transition: color 0.3s 0.3s;
  margin-top: 3px;
  hyphen: auto;

  @media ${ device.lg } {
    font-size: 3.2rem;
  }

  @media ${ device.md } {
    margin-bottom: 0;
    font-size: 2.6rem;
  }

  @media ${ device.sm } {
    position: initial;
    top: initial;
    font-size: 2.4rem;
    color: white;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`

const HomepageTextCarousel = ({ slideshow, currentSlide }) => {
  return (
    <CarouselWithBackground
      currentSlide={currentSlide}
      autosize
      opacityTransition
    >
      {slideshow.map((slide, index) => (
        <Slide
          key={index}
          visible={index === currentSlide}
        >
          <Header color={slide.secondaryColor.hex}>{slide.header}</Header>
          <Text dangerouslySetInnerHTML={{ __html: slide.description }}/>
        </Slide>
      ))}
    </CarouselWithBackground>
  )
}

HomepageTextCarousel.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

export default HomepageTextCarousel
