import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import GatsbyImage from 'gatsby-image'
import { device, colors } from '../../styles/config'
import EventYoutubeVideo from './EventYoutubeVideo'
import EventInfo from './EventInfo'

const Container = styled.div`
  ${ props => props.theme.wrapper }
  margin: initial;
  max-width: initial;
  background: white;
  padding: 5rem;
  overflow: hidden;
  position: relative;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media ${ device.sm } {
    padding: 2rem;
  }
`

const Info = styled(EventInfo)`
  margin-bottom: 3.5rem;
`

const Image = styled(GatsbyImage)`
  width: 100%;
  margin-bottom: 3.125rem;
`

const Description = styled(Col)`
  line-height: 1.625;
  letter-spacing: 0.6px;
  white-space: pre-wrap;
  margin-top: -0.5rem;

  @media ${ device.sm } {
    margin-bottom: 3.125rem;
  }
`

const Details = styled(Description)`
  font-size: 0.875rem;
  letter-spacing: 0.53px;
  line-height: 1.85;
  
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const Disclaimer = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.53px;
  line-height: 1.85;
  position: relative;

  @media ${ device.sm } {
    margin-top: 3.125rem;
  }

  &:before {
    display: block;
      content: '';
      margin-bottom: 0.8125rem;
      width: 500px;
      height: 5px;
      background-color: ${ colors.highlight };
  }

`

const Event = ({
  name,
  time,
  eventType,
  description,
  details,
  video,
  image
}) => {
  return (
    <Container>
      <Row>
        <Col xs={12} smOffset={3} sm={9} mdOffset={4} md={8}>
          <Info
            name={name}
            time={time}
            eventType={eventType}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={3} mdOffset={1} md={3}>
          {image && <Image fluid={image.fluid}/>}
          {video && <EventYoutubeVideo {...video}/>}
        </Col>
        <Description
          xs={12}
          sm={6}
          md={5}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Details xs={12} sm={3} md={2}>
          <div>
            <div dangerouslySetInnerHTML={{ __html: details }}/>
            <Disclaimer>
            Das Mitbringen von eigenen Speisen & Getränken ist nicht gestattet.
            </Disclaimer>
          </div>
        </Details>
      </Row>
    </Container>
  )
}

Event.propTypes = {
  // name: PropTypes.string,
  // header: PropTypes.string,
  // children: PropTypes.node,
}

export default Event
