/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import useFetch from 'react-fetch-hook'

import WeatherClearSvg from '../images/weather-clear.svg'
import WeatherFewCloudsSvg from '../images/weather-few-clouds.svg'
import WeatherCloudsSvg from '../images/weather-clouds.svg'
import WeatherRainSvg from '../images/weather-rain.svg'
import WeatherSnowSvg from '../images/weather-snow.svg'

import degrees0 from '../images/0.svg'
import degrees1 from '../images/1.svg'
import degrees2 from '../images/2.svg'
import degrees3 from '../images/3.svg'
import degrees4 from '../images/4.svg'
import degrees5 from '../images/5.svg'
import degrees6 from '../images/6.svg'
import degrees7 from '../images/7.svg'
import degrees8 from '../images/8.svg'
import degrees9 from '../images/9.svg'
import minus from '../images/minus.svg'
import degree from '../images/degree.svg'

import { colors, device } from '../styles/config'

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 2.5rem;

  opacity: ${ props => props.show ? 1 : 0 };

  @media ${ device.sm } {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0;
    padding: 0.75rem 1.5rem;
    background-color: white;
    transform: translateY(50%);
    border-bottom: 5px solid ${ colors.highlight };
  }
`

const Weather = styled.div`
  font-size: 2.25rem;
  position: relative;
  font-weight: normal;

  @media ${ device.sm } {
    display: flex;
    align-items: center;
  }

  &:before {
    top: 0;
    left: -10px;
    content: '';
    display: block;
    position: absolute;
    transform: translate3d(-2.25rem, -0.75rem, 0);
    /* TODO: read icon prop and server corresponding svg */
    background-image: url(${ props => getWeatherIcon(props.icon) });
    width: 2.5rem;
    height: 1.75rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;

    @media ${ device.md } {
      left: 8px;
      width: 2.2rem;
      height: 1.54rem;
      transform: translate3d(-2.5rem, -0.75rem, 0);
    }

    @media ${ device.sm } {
      position: initial;
      transform: none;
      height: 1.625rem;
      width: 4rem;
      margin-right: 1rem;
    }
  }
`

const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.1875rem;
  letter-spacing: 0.5px;
  margin-left: 0.75rem;
  white-space: pre-wrap;

  @media ${ device.sm } {
    margin-left: 1.5rem;
    white-space: initial;
  }
`

const TemperatureContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  > img {
    margin-right: 2px;
  }

  > img:last-child {
    margin-bottom: auto;
  }
`

const OpeningTimes = () => {
  const [openingMessage, setOpeningMessage] = useState('')
  const { data } = useFetch('https://api.openweathermap.org/data/2.5/weather?id=7290255&appid=47de7ac2158c4b2fb5cd38114d43fe6d&units=metric')
  const { datoCmsOpeningtime: { week } } = useStaticQuery(query)

  const currentDayOfWeek = useMemo(() => {
    switch (new Date().getUTCDay()) {
    case 0:
      return 6
    case 1:
      return 0
    case 2:
      return 1
    case 3:
      return 2
    case 4:
      return 3
    case 5:
      return 4
    case 6:
      return 5
    }
  }, [])

  useEffect(() => {
    setOpeningMessage(getMessage(week[currentDayOfWeek]))
  }, [])

  return (
    <Container show={data && data.weather && openingMessage}>
      {data && data.weather && (
        <Weather icon={data.weather[0].icon}>
          {generateTemperature(data.main.temp)}
        </Weather>
      )}
      {openingMessage && <Text dangerouslySetInnerHTML={{ __html: openingMessage }}/>}
    </Container>
  )
}

function getMessage ({ open, opening, closing, closedMessage, openingSoonMessage, currentlyOpenMessage }) {
  const now = getDailyMinutes(new Date().getTime())
  const openingTime = getDailyMinutes(new Date(opening))
  const closingTime = getDailyMinutes(new Date(closing))

  if (!open || now > closingTime) {
    return closedMessage ? closedMessage.message : ''
  } else if (now > 420 && now < openingTime) {
    // only show when later than 07:00
    return openingSoonMessage ? openingSoonMessage.message : ''
  } else if (now > openingTime && now < closingTime) {
    return currentlyOpenMessage ? currentlyOpenMessage.message : ''
  }
}

function getDailyMinutes (time) {
  return new Date(time).getHours() * 60 + new Date(time).getMinutes()
}

function getWeatherIcon (icon) {
  switch (icon) {
  case '01d':
  case '01n':
    return WeatherClearSvg
  case '02d':
  case '02n':
    return WeatherFewCloudsSvg
  case '03d':
  case '03n':
  case '04d':
  case '04n':
    return WeatherCloudsSvg
  case '09d':
  case '09n':
  case '10d':
  case '10n':
  case '11d':
  case '11n':
    return WeatherRainSvg
  case '13d':
  case '13n':
    return WeatherSnowSvg
  }
}

function generateTemperature (temperature) {
  const numberMap = {
    0: degrees0,
    1: degrees1,
    2: degrees2,
    3: degrees3,
    4: degrees4,
    5: degrees5,
    6: degrees6,
    7: degrees7,
    8: degrees8,
    9: degrees9,
    '-': minus
  }

  return (
    <TemperatureContainer>
      {Math.round(temperature).toString().split('').map((digit, index) => (
        <img key={index} src={numberMap[digit]} alt=""/>
      ))}
      <img src={degree} alt=""/>
    </TemperatureContainer>
  )
}

OpeningTimes.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

const query = graphql`
  query openingtimeQuery {
    datoCmsOpeningtime {
      week {
        open
        day
        opening
        closing
        closedMessage {
          message
        }
        openingSoonMessage {
          message
        }
        currentlyOpenMessage {
          message
        }
      }
    }
    }
`

export default OpeningTimes
