import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import scrollToComponent from 'react-scroll-to-component'
import styled from 'styled-components'

import TextSection from '../components/TextSection'
import EventCalendar from '../components/event/EventCalendar'
import Background from '../components/Background'
import Social from '../components/Social'
import HomepageHeader from '../components/homepage/HomepageHeader'
import Jobs from '../components/Jobs'

import { device, headerHeight } from '../styles/config'

const Container = styled.div`
  transform: translate3d(0,-${ headerHeight.desktop },0);

  @media ${ device.sm } {
    transform: translate3d(0,-${ headerHeight.mobile },0);
  }
`

const KuenstlerTextSection = styled(TextSection)`
  margin-top: 15.625rem;

  @media ${ device.sm } {
    margin-top: 7.8125rem;
  }
`

const CulturePage = ({
  data: {
    datoCmsCulture: {
      aboutHeader,
      cultureHeader,
      about,
      culture,
      kuenstler,
      kuenstlerHeader,
      slideshow,
      winterMode,
      winterSlideshow
    }
  }
}) => {
  const eventsRef = useRef()

  const onProgrammeClick = () => {
    scrollToComponent(eventsRef.current, {
      align: 'top',
      duration: 1250,
      offset: -50,
      ease: 'inOutCube'
    })
  }

  return (
    <Container>
      <HomepageHeader
        winterMode={winterMode}
        slideshow={winterMode ? winterSlideshow : slideshow}
        onProgrammeClick={onProgrammeClick}
      />
      {winterMode &&
      <Background>
        <TextSection columns name="Über die Insel" header={aboutHeader} text={about}/>
        <KuenstlerTextSection columns name="für Künstler" header={kuenstlerHeader} text={kuenstler}/>
        <Social/>
      </Background>
      }
      {!winterMode &&
      <>
        <TextSection columns name="Über die Insel" header={aboutHeader} text={about}/>
        <Background>
          <div ref={eventsRef}>
            <TextSection name="Kulturprogramm" header={cultureHeader} text={culture} id="kultur"/>
            <EventCalendar/>
            <Jobs isHomepage/>
            <TextSection columns name="für Künstler" header={kuenstlerHeader} text={kuenstler}/>
            <Social/>
          </div>
        </Background>
      </>
      }
    </Container>
  )
}

export const query = graphql`
  query CulturePage {
    datoCmsCulture {
      about
      aboutHeader
      culture
      cultureHeader
      kuenstler
      kuenstlerHeader
      winterMode
      winterSlideshow {
        header
        description
        primaryColor {
          hex
        }
        secondaryColor {
          hex
        }
        luminance
        image {
          fluid(maxWidth: 900, imgixParams: {
            w: "900",
            h: "900",
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "edges"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      slideshow {
        header
        description
        primaryColor {
          hex
        }
        secondaryColor {
          hex
        }
        luminance
        image {
          fluid(maxWidth: 1500, imgixParams: {
            w: "1500",
            h: "1500",
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "edges"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default CulturePage
