import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../../styles/config'

const Container = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: ${ ({ width, height }) => `${ height / width * 100 }%` };
  position: relative;

  @media ${ device.sm } {
    margin-bottom: 3.125rem;
  }
`

const YoutubeIframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
`

const YoutubeVideo = ({ providerUid, width, height }) => (
  <Container width={width} height={height}>
    <YoutubeIframe
      id="ytplayer"
      type="text/html"
      allowFullScreen={true}
      src={`https://www.youtube.com/embed/${ providerUid }?autoplay=0&modestbranding=1&iv_load_policy=3&rel=0`}
      frameBorder="0"
    />
  </Container>
)

YoutubeVideo.propTypes = {
  providerUid: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default YoutubeVideo
