import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'

import { colors, device } from '../styles/config'

const Jobs = ({ isHomepage }) => {
  const { datoCmsContact: { jobsText, jobsTitle, jobsSubtitle, jobs } } = useStaticQuery(jobsQuery)

  if (!jobs || jobs.length === 0) {
    return null
  }

  return (
    <Container isHomepage={isHomepage}>
      {isHomepage && (
        <Row>
          <LeftHeader xs={12} sm={3}>
            <h4>{jobsSubtitle}</h4>
          </LeftHeader>
          <Col xs={12} sm={9}>
            <RightHeader>{jobsTitle}</RightHeader>
          </Col>
        </Row>
      )}
      <Row>
        {!isHomepage && (
          <Col sm={4} xs={12}>
            <Header>{jobsTitle}</Header>
          </Col>
        )}
        <Text sm={4} smOffset={isHomepage && 3} xs={12} dangerouslySetInnerHTML={{ __html: jobsText }}/>
        <Col sm={4} xs={12}>
          {jobs.map((job, index) => (
            <Job key={index}
              href={job.link || `mailto:kontakt@inselberlin.de?subject=Bewerbung%3A%20${ encodeURI(job.name) }`}
              title={job.name}
              target="_blank"
              rel="noopener noreferrer"
            ><h3>{job.name}</h3></Job>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 5rem;

  ${ props => props.isHomepage && css`
      ${ props.theme.wrapper };
      margin-bottom: 15.625rem;

      @media ${ device.sm } {
        margin-bottom: 7.8125rem;
      }
  ` }
`

const Header = styled.h1`
  text-align: right;

  @media ${ device.sm } {
    text-align: left;
    margin-bottom: 1rem;
  }
`

const Text = styled(Col)`
  p {
    margin-bottom: 1rem;
  }
`

const LeftHeader = styled(Col)`
  padding-right: 0;

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }

    @media ${ device.sm } {
      margin-bottom: 3.125rem;
    }
  }
`

const RightHeader = styled.h1`
  hyphens: auto;
`

const Job = styled.a`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background: white;
  border-top: 3px solid ${ colors.highlight };
  color: ${ colors.primary };
  margin-bottom: 1.875rem;
  cursor: pointer;

  @media ${ device.hover } {
    &:hover {
      color: ${ colors.secondary };
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media ${ device.sm } {
    &:first-child {
      margin-top: 1rem;
    }
  }
`

const jobsQuery = graphql`
  query JobsQuery {
    datoCmsContact {
      info
      text
      jobsTitle
      jobsSubtitle
      jobsText
      jobs {
        name
        link
      }
    }
  }
`

export default Jobs
